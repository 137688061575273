@import "~@studyportals/styles-abstracts/abstracts.scss";

.Drawer {
	z-index: $PentHouse;
	width: 100%;
	height: 100%;
	background-color: transparent;
	position: fixed;
	top: 0;
	right: 0;

	.Overlay {
		background: rgba(49, 50, 51, 0.7);
		cursor: pointer;

		@include overlay(0);
	}

	.Wrapper {
		display: flex;
		flex-direction: column;

		background: $GreyLL;
		width: 100%;

		&--is-opening {
			animation: openDrawer 0.4s ease-in-out forwards;
		}

		&--is-closing {
			animation: closeDrawer 0.4s ease-in-out forwards;
		}

		&--full-to-right {
			animation: fullToRight 0.4s ease-in-out forwards;
		}

		&--right-to-full {
			animation: openDrawer 0.4s ease-in-out forwards;
		}

		@keyframes closeDrawer {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(100%);
			}
		}

		@keyframes openDrawer {
			0% {
				transform: translateX(100%);
			}
			100% {
				transform: translateX(0);
			}
		}

		@keyframes fullToRight {
			0% {
				width: 100%;
			}
			100% {
				width: 384px;
			}
		}
	}

	.Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: white;
		border-bottom: 1px solid $GreyL;
		font-size: 1.7rem;
		min-height: 3rem;

		@include PaddingAreas(0.5, 1);

		.CloseButton {
			background-color: transparent;
			border: 0;
			cursor: pointer;
			margin-left: auto;
			padding: 0;

			.CloseIcon {
				color: $GreyD;
				font-size: 2.6rem;
			}
		}
	}

	.Footer {
		//needs to be always at the bottom
		position: absolute;
		bottom: 0;
		width: 100%;
	}


	&--right {
		.Wrapper {
			position: absolute;
			top: 0;
			right: 0;
			width: fit-content;
			min-width: 384px;
			height: 100%;
		}
	}

	@include Breakpoint(Small, Medium) {
		&--right {
			.Wrapper {
				width: 100%;
				min-width: 100%;
			}
		}
	}

	&--full {
		.Wrapper {
			width: 100%;
			min-width: 100%;
			height: 100%;
		}

		.Overlay {
			display: none;
		}
	}
}